@import '../../styles/shared';

$scope: '.StretchFlow';

#{$scope} {
  @extend %Component--container;

  &-heading {
    @extend %Page--header;
  }

  &-description {
    text-align: center;
    font-size: var(--fontSize--m);
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-description {
      @extend %Page--descriptionMobile;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-description {
      font-size: var(--fontSize--m);
    }
  }
}
