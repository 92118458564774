%Component--container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2.125rem;
}

%Component--containerMobile {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  padding-bottom: 1rem;
}

%Page--header {
  font-size: var(--fontSize--xxl);
  text-align: center;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
}

%Page--headerMobile {
  font-size: var(--fontSize--l);
  font-weight: 400;
}

%Page--headerVaha {
  font-size: var(--fontSize--xxxl);
  font-weight: 400;
}

%Page--descriptionMobile {
  font-size: var(--fontSize--xxxs);
  line-height: 1.4;
}

%Page--bottomGradient {
  --gradientStop: 25%;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35rem;
  background-image: linear-gradient(
    0deg,
    var(--color-default) 0,
    transparent var(--gradientStop)
  );
  pointer-events: none;
}

%Page--bottomGradientMobile {
  --gradientStop: 10%;
}

%Page--bottomGradientVaha {
  --gradientStop: 20%;
}

%CustomGradient {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      45deg,
      rgba(0 0 0 / 95%) 0%,
      rgba(0 0 0 / 80%) 10%,
      rgba(0 0 0 / 40%) 40%,
      rgba(0 0 0 / 10%) 70%,
      transparent 100%
    );
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

%Component--carousel {
  /* stylelint-disable-next-line selector-class-pattern */
  .slick-dots {
    li {
      margin-right: 0.375rem;
      margin-left: 0.375rem;
    }

    button::before {
      color: var(--color-defaultContrast) !important;
      opacity: 0.5 !important;
      font-size: 1.125rem !important;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .slick-active {
      button::before {
        opacity: 1 !important;
      }
    }
  }
}

%Component--carouselMobile {
  /* stylelint-disable-next-line selector-class-pattern */
  .slick-dots {
    li {
      width: 0.375rem !important;
      margin-right: 0.125rem;
      margin-left: 0.125rem;
    }

    button::before {
      font-size: 0.375rem !important;
    }
  }
}

%Component--carouselVaha {
  /* stylelint-disable-next-line selector-class-pattern */
  .slick-dots {
    li {
      width: 0.375rem !important;
      margin-right: 0.425rem;
      margin-left: 0.425rem;
    }

    button::before {
      font-size: 0.625rem !important;
    }
  }
}
