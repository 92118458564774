$scope: '.Image';

#{$scope} {
  border-radius: 1rem;
  position: relative;
  height: 0;
  padding-top: calc(var(--Image-aspectRatio) * 100%);

  &-source {
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}
