/* stylelint-disable selector-class-pattern */
@import 'react-h5-audio-player/src/styles';

.rhap_button-clear {
  display: flex;
  align-items: center;
}

@media screen and (width > 768px) {
  .rhap_container {
    padding-inline: 1.25rem;
  }
}
