@import '../../../styles/shared';

$scope: '.HydrationStationView';

#{$scope} {
  @extend %Component--container;

  max-width: 100%;

  &-heading {
    @extend %Page--header;
  }

  &-carousel {
    @extend %Component--carousel;

    margin-bottom: 8.5rem;
    max-width: 100%;
  }

  &-legal {
    padding-top: 2rem;
    text-align: center;
    max-width: 80%;
    font-size: var(--fontSize--xxxs);
    line-height: 1.2;
  }

  &--mobile &-legal {
    padding-top: 1rem;
    max-width: 100%;
    font-size: var(--fontSize--xxxxs);
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      margin-bottom: 1rem;
    }

    #{$scope}-carousel {
      @extend %Component--carouselMobile;

      margin-bottom: 3.25rem !important;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-carousel {
      @extend %Component--carouselVaha;

      margin-bottom: 11rem;
    }
  }
}
