@import '../../../styles/shared';

$scope: '.ScentingStationSingleItem';

#{$scope} {
  text-align: center;

  &-title {
    font-weight: 700;
    font-size: 2.875rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }

  &-vaha &-title {
    font-size: 2rem;
  }

  &-description {
    font-size: 2.25rem;
  }

  &-vaha &-description {
    font-size: var(--fontSize--s);
  }

  &-mobile &-description {
    font-size: 0.6875rem;
  }

  &-imageContainer {
    @extend %CustomGradient;

    &::before {
      border-radius: 1rem;
      background-image: linear-gradient(
        0deg,
        rgba(0 0 0 / 70%) 0,
        transparent 60%
      );
    }
  }

  &-image {
    width: 100%;
    max-width: 100%;
    height: 26rem;
    object-fit: cover;
    margin-bottom: 5rem;
    border-radius: 1rem;
  }

  &--mobile {
    #{$scope}-title {
      margin-bottom: 1.25rem;
      font-size: var(--fontSize--xxxs);
    }

    #{$scope}-imageContainer {
      &::before {
        border-radius: 0.5rem;
      }
    }

    #{$scope}-image {
      border-radius: 0.5rem;
      height: 6.5rem;
      margin-bottom: 1rem;
    }

    #{$scope}-description {
      font-size: 0.6875rem;
    }
  }

  &--vaha {
    #{$scope}-title {
      font-size: 2rem;
      margin-bottom: 4rem;
    }

    #{$scope}-image {
      height: 16.5rem;
      margin-bottom: 4rem;
    }

    #{$scope}-description {
      font-size: var(--fontSize--s);
    }
  }
}
