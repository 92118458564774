.App {
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: var(--container--maxWidth);
  }

  &-blurred {
    filter: blur(0.5rem);
  }

  &--mobile {
    line-height: 1.2;
    height: 100%;
  }
}
