$scope: '.Progress';

#{$scope} {
  --sizeFactor: var(--Progress-sizeFactor, 4);
  --lineHeight: calc(var(--sizeFactor) * 1px);
  --handleSize: calc(var(--lineHeight) * 4);

  display: flex;
  flex-direction: column;

  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-full {
    width: 100%;
    background-color: #5f5f5f;
    height: var(--lineHeight);
  }

  &-behind {
    width: calc(var(--percent) * 1%);
    background-color: currentcolor;
    height: var(--lineHeight);
    position: absolute;
  }

  &-progressHandle {
    --alignment: calc(calc(50% - calc(var(--lineHeight) * 0.5)) * -1);

    position: absolute;
    background-color: currentcolor;
    height: var(--handleSize);
    width: var(--handleSize);
    border-radius: 50%;
    left: calc(var(--percent) * 1%);
    transform: translate(var(--alignment), var(--alignment));
  }

  input[type="range"] {
    // keep it invisible but interactive
    opacity: 0;

    // place it on top of the progress line
    position: absolute;
    inset: 0;
    margin: 0;
    padding: 0;

    // make it taller so it's easier for user to tap
    height: 300%;
    top: -100%;

    // adjust, so the range handle is aligned with our custom handle
    width: calc(100% + var(--handleSize));
    left: calc(var(--handleSize) * -0.5);
  }
}
