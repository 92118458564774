@import '../../styles/shared';

$scope: '.SoundWorld';

#{$scope} {
  @extend %Component--container;

  &-heading {
    @extend %Page--header;
  }

  &-description {
    --Paragraph-fontSize: var(--fontSize--m);

    text-align: center;
  }

  &-records {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-top: 4em;
    width: 100%;
  }

  &-record {
    display: flex;
    flex-direction: column;
    gap: 0.75em;
  }

  &-recordCover {
    @extend %CustomGradient;

    display: flex;
    overflow: hidden;
    border-radius: 1em;
    height: 16em;

    &::before {
      background-image: linear-gradient(
        30deg,
        rgb(0 0 0) 0%,
        rgba(0 0 0 / 80%) 30%,
        rgba(0 0 0 / 40%) 70%,
        rgba(0 0 0 / 10%) 100%
      );
    }
  }

  &-recordCoverImage {
    object-fit: cover;
    width: 100%;
  }

  &-recordTitle {
    position: absolute;
    bottom: 0.25em;
    left: 0.5em;
    font-weight: 700;
    font-size: var(--fontSize--l);
    max-width: 80%;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-description {
      @extend %Page--descriptionMobile;
    }

    #{$scope}-recordTitle {
      font-size: var(--fontSize--xxs);
      bottom: 0.5em;
      left: 1em;
    }

    #{$scope}-recordCover {
      height: 6em;
      border-radius: 0.5em;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }
  }
}
