@import './../../styles/shared';

$scope: '.Faq';

#{$scope} {
  @extend %Component--container;

  &-heading {
    @extend %Page--header;

    text-transform: none;
  }

  &-faqItemsContainer {
    width: 100%;
    padding: 0 5vw;
  }

  &-separator {
    border-bottom: 1px solid rgb(255 255 255 / 60%);
    padding: 0 5vw;
    margin: 0.75rem auto;
    width: 100%;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-faqItemsContainer {
      padding: 0;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }
  }
}
