$scope: '.ExerciseVideo';

#{$scope} {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  max-width: 80vw;
  width: 100%;

  &-introVideo,
  &-video {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  &[data-is-intro] &-video {
    display: none;
  }

  &:not([data-is-intro]) &-introVideo {
    display: none;
  }

  &-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    overflow: hidden;
    background-color: transparent;
    border-width: 0;
  }

  &-skipIntro {
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    background: #000;
    padding: 8px;
    border: 2px solid #fff;
    border-radius: 8px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &--desktop &-introVideo,
  &--desktop &-video {
    min-height: 30rem;
  }
}

// hide built-in chrome controls for the fullscreen video
video::-webkit-media-controls-enclosure {
  display: none !important;
}
