@import './../../styles/shared';

$scope: '.ImageTileWithText';

#{$scope} {
  &-link {
    @extend %CustomGradient;

    cursor: pointer;
    display: block;

    &::before {
      border-radius: 1em;
    }
  }

  &-image {
    border-radius: 1em;
    object-fit: cover;
    width: 100%;
  }

  &-text {
    position: absolute;
    bottom: 1em;
    left: 1em;
    font-weight: 700;
    font-size: var(--fontSize--xl);
    text-transform: uppercase;
  }

  &--mobile {
    #{$scope}-link {
      &::before {
        border-radius: 0.5em;
      }
    }

    #{$scope}-image {
      border-radius: 0.5em;
    }

    #{$scope}-text {
      font-size: var(--fontSize--xxs);
      font-weight: 600;
      bottom: 1.25em;
      left: 1em;
    }
  }
}
