@import './../../styles/shared';

$scope: '.QRContainer';

#{$scope} {
  @extend %Component--container;

  width: 100%;

  &-qr {
    width: 6.5rem;
    height: 6.5rem;
  }

  &-text {
    padding-top: var(--QRContainer-textPaddingTop, 1.5rem);
    font-size: 1.5625rem;
    font-weight: 700;
  }
}
