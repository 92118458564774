@import './../../styles/shared';

$scope: '.Modal';

#{$scope} {
  position: fixed;
  inset: 0;

  &-box {
    position: absolute;
    padding: var(--Modal-boxPadding, 2rem 2rem 3.5rem);
    background-color: var(--Modal-boxBackgroundColor, transparent);
    background-image: var(
      --Modal-boxBackgroundImage,
      url('../../../public/media/images/blurry-background-black.png')
    );
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    max-width: var(--Modal-boxMaxWidth, 30rem);
    min-width: var(--Modal-boxMinWidth, 34rem);
    min-height: 34rem;
    max-height: 85vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: var(--Modal-boxAlignItems, initial);
    justify-content: center;
  }

  &-backdrop {
    position: absolute;
    inset: 0;
    background-color: var(--Modal-backdropBackgroundColor, transparent);
  }

  &-closeIconContainer {
    display: flex;
    justify-content: flex-end;
  }

  &-closeIcon {
    cursor: pointer;
    font-size: 1.5rem;
  }

  &-body {
    font-size: var(--fontSize--xl);
    font-weight: 700;
    overflow: var(--Modal-bodyOverflow, auto);
    display: flex;
    flex-direction: column;
  }

  &--mobile {
    #{$scope}-box {
      padding: var(--Modal-mobileBoxPadding, 1rem 1.5rem 2rem);
      border-radius: 0.5rem;
      min-height: auto;
      min-width: var(--Modal-mobileBoxMinWidth, 13rem);
    }

    #{$scope}-closeIcon {
      width: 0.75rem;
    }
  }

  &--vaha {
    #{$scope}-box {
      border-radius: 1rem;
    }
  }
}
