@import '../../styles/shared';

$scope: '.PrivacyPolicy';
$uc-scope: '.uc';

#{$scope} {
  @extend %Component--container;

  font-size: 1.5rem;
  margin-bottom: 4rem;
  width: 100%;

  #app-uc-embed-place {
    --darkWBackground: transparent;
    --darkWfontColor: #fff;

    #{$uc-scope}-consent-title, #{$uc-scope}-category-description {
      color: #fff !important;
    }

    #{$uc-scope}-icon-info:after, input:disabled+label:after {
      color: #fff !important;
      border-color: #fff !important;
    }

    #{$uc-scope}-checkbox input+label:hover:before, #{$uc-scope}-checkbox input+label:after {
      border-color: #fff !important;
    }

    #{$uc-scope}-checkbox input:disabled+label {
      cursor: default;
    }
  }

  &-textContainer {
    width: 100%;
  }

  &-boldText {
    font-weight: 700;
  }

  &-textWithLeftPadding {
    padding-left: 2rem;
  }

  &-link {
    color: currentcolor;
  }

  &-buttonLink {
    display: inline-block;
    background-color: transparent;
    border: navajowhite;
    font-size: inherit;
    padding: 0;
    text-decoration: underline;

    &:hover, :focus {
      text-decoration: underline;
    }
  }

  &-gradient {
    @extend %Page--bottomGradient;
  }

  &-heading {
    @extend %Page--header;
  }

  &-midSection {
    margin-top: 7rem;
  }

  &-midHeading {
    margin-top: 8rem;
  }

  &--mobile {
    @extend %Component--containerMobile;

    margin-bottom: 2rem;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      font-size: var(--fontSize-m);
    }

    #{$scope}-midSection {
      margin-top: 3rem;
    }

    #{$scope}-textContainer {
      font-size: var(--fontSize--xxxs);
    }

    #{$scope}-gradient {
      @extend %Page--bottomGradientMobile;
    }
  }
}
