@import './../../styles/shared';

$scope: '.GroupGallery';

#{$scope} {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;

  &-grid {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    width: 100%;
    gap: 0.75rem;
  }

  &-gridCell {
    @extend %CustomGradient;

    min-height: 18rem;
    min-width: 100%;
    max-width: 29rem;
    border-radius: 1rem;
    position: relative;
    border-width: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    background-position: center;
    background-size: cover;
  }

  &-gridCellTitle {
    font-size: var(--fontSize--s);
    text-transform: uppercase;
    position: absolute;
    bottom: 1.75rem;
    left: 1.75rem;
    text-align: left;
    font-weight: 700;
    line-height: 1.2;
    white-space: pre-line;
  }

  &-groups {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    column-gap: 1rem;
    padding-top: 6rem;
    padding-bottom: 4.125rem;
  }

  &-group {
    background-color: transparent;
    border-width: 0;
    font-size: var(--fontSize--s);
    white-space: pre;
    text-transform: uppercase;
    padding: 0.75rem 1.25rem;
    border-radius: 0.75rem;

    &--selected {
      color: var(--color-default);
      font-weight: 700;
      background-color: var(--color-defaultContrast);
    }
  }

  &--mobile {
    #{$scope}-group {
      font-size: var(--fontSize--xxxxs);
      padding: 0.25rem;
      border-radius: 0.25rem;
    }

    #{$scope}-groups {
      padding-top: 1.375rem;
      padding-bottom: 1rem;
      column-gap: 0;
    }

    #{$scope}-grid {
      gap: 0.25rem;
    }

    #{$scope}-gridCell {
      min-height: 8rem;
      border-radius: 0.375rem;
    }

    #{$scope}-gridCellTitle {
      font-size: var(--fontSize--xxxs);
      bottom: 0.62rem;
      left: 0.62rem;
      hyphens: auto;
    }
  }

  &--vaha {
    #{$scope}-groups {
      padding-top: 5.75rem;
      padding-bottom: 4.25rem;
      column-gap: 0.125rem;
    }

    #{$scope}-group {
      font-size: 1.25rem;
      padding: 0.375rem;
      border-radius: 0.375rem;
    }
  }

  &--mobile,
  &--vaha {
    // Row 1
    #{$scope}-gridCell:nth-child(6n-5) {
      grid-column: span 10;
    }
    #{$scope}-gridCell:nth-child(6n-4) {
      grid-column: span 20;
    }
    #{$scope}-gridCell:nth-child(6n-3) {
      grid-column: span 20;
    }

    // Row 2
    #{$scope}-gridCell:nth-child(6n-2) {
      grid-column: span 10;
    }
    #{$scope}-gridCell:nth-child(6n-1) {
      grid-column: span 15;
    }
    #{$scope}-gridCell:nth-child(6n) {
      grid-column: span 15;
    }
  }

  &--desktop {
    // Row 1
    #{$scope}-gridCell:nth-child(9n-8) {
      grid-column: span 8;
    }
    #{$scope}-gridCell:nth-child(9n-7) {
      grid-column: span 11;
    }
    #{$scope}-gridCell:nth-child(9n-6) {
      grid-column: span 11;
    }

    // Row 2
    #{$scope}-gridCell:nth-child(9n-5) {
      grid-column: span 10;
    }
    #{$scope}-gridCell:nth-child(9n-4) {
      grid-column: span 8;
    }
    #{$scope}-gridCell:nth-child(9n-3) {
      grid-column: span 12;
    }

    // Row 3
    #{$scope}-gridCell:nth-child(9n-2) {
      grid-column: span 8;
    }
    #{$scope}-gridCell:nth-child(9n-1) {
      grid-column: span 15;
    }
    #{$scope}-gridCell:nth-child(9n) {
      grid-column: span 7;
    }
  }
}
