html {
  height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html,
body {
  margin: 0;
  align-items: center;
  width: 100%;
  height: 100%;
}

html,
body,
.Root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Root {
  width: 100%;
}

body {
  font-family: 'Porsche Next', 'Arial Narrow', sans-serif;
  line-height: 1.4;
  overflow: hidden auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0;
}

svg {
  fill: currentcolor;
}

button {
  cursor: pointer;
  font-family: inherit;
  color: currentcolor;
}

a {
  color: var(--Link-color, inherit);
}
