@import '../../styles/shared';

$scope: '.ReadAndRecharge';

#{$scope} {
  @extend %Component--container;

  align-items: flex-start;

  &-content {
    padding: 0 6em;
  }

  &-heading {
    @extend %Page--header;

    align-self: center;
  }

  &-caption {
    align-self: flex-start;
    font-size: var(--fontSize--m);
    font-weight: 600;
    text-transform: uppercase;
  }

  &-title {
    font-size: var(--fontSize--l);
    line-height: 1;
    font-weight: 700;
    align-self: flex-start;
    margin-bottom: 0.5em;
  }

  &-paragraph {
    padding-bottom: 1em;
  }

  &-sections {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding-top: 1.5em;
  }

  &-section {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    background-color: transparent;
    border-width: 0;
    text-align: left;
    gap: 2em;
    cursor: pointer;
    padding: 0;
  }

  &-preview {
    @extend %CustomGradient;
  }

  &-preview::before,
  &-previewImage,
  &-tileImage {
    height: auto;
    border-radius: 1.75em;
    object-fit: cover;
  }

  &-previewImage {
    width: 48em;
  }

  &-tile {
    @extend %CustomGradient;

    display: flex;
    margin-bottom: 2em;
  }

  &-tileImage {
    width: 100%;
  }

  &-sectionDetails {
    font-size: var(--fontSize--s);
    display: flex;
    flex-direction: column;
  }

  &-itemCategory {
    text-transform: uppercase;
    font-size: var(--fontSize--m);
    line-height: 1.2;
  }

  &-itemTitle {
    font-weight: 700;
    font-size: var(--fontSize--l);
    line-height: 1.4;
  }

  &-more {
    font-size: var(--fontSize--s);
    font-weight: 700;
    text-decoration: underline;
    margin-top: 0.25em;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-content {
      padding: 0 2vw;
    }

    #{$scope}-previewImage {
      width: 30vw;
    }

    #{$scope}-tile {
      width: 100%;
    }

    #{$scope}-tileImage {
      width: 100%;
      margin-bottom: 1em;
    }

    #{$scope}-caption {
      line-height: 1.8;
      font-size: var(--fontSize--xxs);
    }

    #{$scope}-title {
      font-size: var(--fontSize--xs);
    }

    #{$scope}-paragraph {
      font-size: var(--fontSize--xxs);
    }

    #{$scope}-sections {
      padding: 1em 0;
      gap: 1em;
    }

    #{$scope}-section {
      gap: 1.5em;
    }

    #{$scope}-preview::before,
    #{$scope}-previewImage,
    #{$scope}-tileImage {
      border-radius: 1em;
    }

    #{$scope}-itemCategory {
      font-size: var(--fontSize--xxs);
      line-height: 1.2;
    }

    #{$scope}-itemTitle {
      font-size: var(--fontSize--xs);
      line-height: 1.4;
    }

    #{$scope}-more {
      font-size: var(--fontSize--xxs);
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-content {
      padding: 0 4em;
    }

    #{$scope}-previewImage {
      width: 24em;
    }

    #{$scope}-tile {
      width: 100%;
    }

    #{$scope}-tileImage {
      width: 100%;
      margin-bottom: 1em;
    }

    #{$scope}-caption {
      line-height: 1.8;
    }

    #{$scope}-sections {
      padding: 3em 0;
      gap: 3em;
    }

    #{$scope}-section {
      gap: 3em;
    }

    #{$scope}-preview::before,
    #{$scope}-previewImage,
    #{$scope}-tileImage {
      border-radius: 1em;
    }
  }
}
