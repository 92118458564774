:root {
  --container--maxWidth: 104rem; // 96rem + 2 * 4rem (paddings)

  // Colors
  --color--purple: #475292;

  // Font Sizes
  --fontSize--xxxxs: 0.5625rem;
  --fontSize--xxxs: 0.75rem;
  --fontSize--xxs: 1rem;
  --fontSize--xs: 1.25rem;
  --fontSize--s: 1.875rem;
  --fontSize--m: 2.1875rem;
  --fontSize--l: 2.5rem;
  --fontSize--xl: 3.125rem;
  --fontSize--xxl: 6.5625rem;
  --fontSize--xxxl: 8.125rem;
}
