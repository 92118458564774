.MirrorMode {
  &-modal {
    --Modal-backdropBackgroundColor: #000;
    --Modal-boxAlignItems: center;
  }

  &-modalText {
    padding: 0 1rem 2rem;
    text-align: center;
    line-height: 1.2;
  }

  &-go {
    border-radius: 1rem;
    border: 2px solid var(--color-defaultContrast);
    font-size: var(--fontSize--m);
    font-weight: 700;
    padding: 0;
    margin: 0 2rem;
    line-height: 2;
    cursor: pointer;
    background-color: rgba(0 0 0 / 70%);
  }

  &-hashtag {
    position: fixed;
    inset: 0;
    background-color: #000;
    z-index: 1;
    font-size: var(--fontSize--xxl);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: 4;
  }
}
