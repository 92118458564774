@import './../../../styles/shared';

$scope: '.BrainTeaserView';

#{$scope} {
  @extend %Component--container;

  width: 100%;

  &-heading {
    @extend %Page--header;
  }

  &-title {
    font-size: var(--fontSize--xl);
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
  }

  &-paragraph {
    margin-top: 1.5em;
  }

  &-buttons {
    display: flex;
    font-size: var(--fontSize--m);
    gap: 1.5em;
    padding: 2.25em 0;
  }

  &-button {
    border-radius: 0.5em;
    padding: 0.25em 1em;
    border: 0.2em solid var(--color-defaultContrast);
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    min-width: 8em;
    text-align: center;

    &--active {
      color: var(--color-default);
      background: var(--color-defaultContrast);
    }
  }

  &-solution {
    padding: 0.3em;
    position: relative;
    width: 100%;
    isolation: isolate;
    display: flex;
  }

  &-solutionBackground {
    position: absolute;
    inset: 0;
    border-radius: 1em;
    background: linear-gradient(230deg, currentcolor 60%, var(--color-default));
  }

  &-solutionBody {
    padding: 4em;
    background-color: var(--color-default);
    border-radius: 1em;
    z-index: 1;
    flex: 1;
  }

  &-solutionTitle {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.25em;
    font-size: var(--fontSize--xl);
  }

  &-modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &-modalButton {
    border-radius: 0.75em;
    border: 2px solid currentcolor;
    margin-top: 1.25em;
    padding: 0.75em 1em;
    width: fit-content;
    font-size: var(--fontSize--m);
    font-weight: 400;
    cursor: pointer;
  }

  &-modalText {
    padding: 1.5em 0 0.5em;
  }

  &-tile,
  &-solutionTile {
    @extend %CustomGradient;

    display: flex;
    overflow: hidden;
    border-radius: 1em;

    &::before {
      border-radius: 1em;
    }
  }

  &-tileImage,
  &-solutionTileImage {
    object-fit: cover;
    width: 100%;
  }

  &-solutionTile {
    margin-top: 2em;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-title {
      margin-top: 1em;
      font-size: 0.875em;
      align-self: flex-start;
    }

    #{$scope}-question {
      padding: 0 2em;
    }

    #{$scope}-buttons {
      font-size: var(--fontSize--xxxs);
    }

    #{$scope}-solution {
      padding: 0.1em;
    }

    #{$scope}-solutionTitle {
      font-size: var(--fontSize--xxs);
      margin-bottom: 0.5em;
    }

    #{$scope}-solutionTile {
      margin-top: 1em;
    }

    #{$scope}-solutionBody {
      padding: 2em;
    }

    #{$scope}-tile {
      @extend %CustomGradient;

      &::before {
        border-radius: 0.5em;
      }
    }

    #{$scope}-tileImage {
      border-radius: 0.5em;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-title {
      font-size: 2.8125em;
    }

    #{$scope}-question {
      padding: 0 3em;
    }

    #{$scope}-qrCode {
      margin-top: 4em;
    }
  }
}

#{$scope}-modal--mobile {
  #{$scope}-modalText {
    font-size: var(--fontSize--xxs);
  }

  #{$scope}-modalButton {
    padding: 0.75em 1.25em;
    margin-top: 1em;
    font-size: var(--fontSize--xxxs);
  }
}
