$scope: '.VideoPlayer';

#{$scope} {
  display: flex;
  justify-content: center;
  align-items: center;

  &-video {
    max-width: 90%;
    border-radius: 1rem;
    opacity: 0;
    width: 0;
  }

  &[data-has-started] &-video {
    opacity: 1;
    width: initial;
  }

  &-pauseButton,
  &-playButton {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 6rem;
    aspect-ratio: 1;
    border-width: 0;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
  }

  &-pauseIcon,
  &-playIcon {
    width: 100%;
  }

  &-preview {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &-timeControls {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 16px 32px;

    // ensure white time digits are visible on top of a light video background
    background-image: linear-gradient(to bottom, #64646400 20%, #32323280 80%);
  }

  &-progress {
    width: 100%;
    padding: 8px 16px;
  }

  &-timing {
    display: flex;
    justify-content: space-between;
    font-size: var(--fontSize--xxxs);
  }

  &-volume {
    position: absolute;
    right: 40px;
    bottom: 80px;
  }

  &-playbackControls {
    pointer-events: none;
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity 0.5s;

    > * {
      pointer-events: all;
    }
  }

  &[data-is-user-active="true"] &-playbackControls {
    opacity: 1;
  }

  &--mobile {
    #{$scope}-video {
      border-radius: 0.5rem;
      max-width: 80%;
    }
  }

  &--vaha {
    #{$scope}-video {
      height: 100%;
      max-width: 100%;
    }
  }
}
