@import './../../styles/shared';

$scope: '.BrainTeaser';

#{$scope} {
  @extend %Component--container;

  &-heading {
    @extend %Page--header;
  }

  &-description {
    font-size: var(--fontSize--s);
    margin-bottom: 3em;
    text-align: center;
    width: 100%;
    max-width: 80vw;
  }

  &-tileImage {
    margin-bottom: 1.25em;
    width: 100%
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-description {
      @extend %Page--descriptionMobile;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }
  }
}
