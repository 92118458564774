@import './../../../styles/shared';

$scope: '.StretchFlowView';

#{$scope} {
  @extend %Component--container;

  &-heading {
    @extend %Page--header;

    font-size: 4.375rem;
    line-height: 5.5rem;
  }

  &-qrCode {
    padding: 4rem 0 0;
    flex-grow: 0;
  }

  &-description {
    text-align: center;
    font-size: var(--fontSize--s);
    margin-bottom: 3rem;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      font-size: 1.125rem;
      line-height: 1.3125rem;
    }

    #{$scope}-description {
      font-size: var(--fontSize--xs);
    }
  }

  &--vaha &-heading {
    @extend %Page--headerVaha;

    font-size: 50px;
    line-height: 4.0625rem;
  }
}
