@import './../../styles/shared';

$scope: '.Home';

#{$scope} {
  @extend %Component--container;

  &-heading {
    max-width: 38rem;
    font-size: var(--fontSize--xxl);
    line-height: 1.2;
    text-align: center;
    margin-bottom: 4.5rem;
    text-transform: uppercase;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 2rem;
  }

  &-gridCell {
    @extend %CustomGradient;

    min-height: 24rem;
    max-height: 24rem;
    min-width: 100%;
    max-width: 29rem;
    border-radius: 1rem;
    border-width: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    cursor: pointer;
  }

  &-gridCellTitle,
  &-gridCellTitle--wideText {
    font-size: var(--fontSize--xl);
    line-height: 1.2;
    text-transform: uppercase;
    width: 70%;
    max-width: 22rem;
    position: absolute;
    bottom: 2rem;
    left: 1.75rem;
    text-align: left;
    font-weight: 700;
  }

  &-gridCellTitle--wideText {
    max-width: 40rem;
  }

  [data-page-name='stretching'] {
    white-space: pre;
  }

  &-gridCellImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-fullWidth {
    grid-column: span 2;

    // Custom solution so if the "Read and Recharge" section has full width, the face of the woman is fully visible
    &[data-page-name='read-and-recharge'] {
      background-image: url('../../../public/media/images/desktop/chargingLounge_readAndRecharge.jpg');
    }

    &[data-page-name='faq'] {
      background-image: url('../../../public/media/images/desktop/chargingLounge_faq.png');
    }

    &[data-page-name='read-and-recharge'],
    &[data-page-name='faq'] {
      background-repeat: no-repeat;
      background-size: cover;

      img {
        visibility: hidden;
      }
    }
  }

  &-withoutTextTransform {
    text-transform: none;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      font-size: var(--fontSize--m);
      max-width: 60vw;
      margin-bottom: 3rem;
    }

    #{$scope}-gridCellTitle,
    #{$scope}-gridCellTitle--wideText {
      font-size: var(--fontSize--xxs);
      max-width: 7rem;
      bottom: 0.875rem;
      left: 0.75rem;
    }

    #{$scope}-gridCell {
      min-height: 9rem;
      max-height: 10rem;
    }

    #{$scope}-grid {
      gap: 0.75rem;
    }
  }

  &--vaha {
    #{$scope}-gridCellTitle--wideText {
      max-width: 30rem;
    }
  }

  &--desktop &-heading {
    max-width: fit-content;
  }

  &--desktop &-gridCell {
    min-height: 21.5rem;
    max-height: 21.5rem;
  }
}
