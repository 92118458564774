$scope: '.Footer';

#{$scope} {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.375rem;
  padding: 1.875rem 0;

  &-logo {
    width: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &-logoButton {
    border-width: 0;
    background-color: transparent;
    color: currentcolor;
    padding: 0;
  }

  &-footerLinks {
    display: flex;
    align-items: center;
    gap: 6.25rem;
    font-size: var(--fontSize--xs);
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.6;
  }

  &-imprint,
  &-privacyPolicy {
    cursor: pointer;
  }

  &-modal {
    --Modal-boxMaxWidth: 34rem;
    --Modal-bodyOverflow: visible;
    --QRContainer-textPaddingTop: 2.3125rem;
  }

  &-modalText {
    padding: 0 3rem 3rem;
    text-align: center;
    line-height: 1.2;
  }

  &-qrCode {
    width: 6.375rem;
    align-self: center;
  }

  &--mobile {
    padding: 0.25rem 0 1rem;

    #{$scope}-logo {
      width: 5rem;
    }

    #{$scope}-footerLinks {
      font-size: var(--fontSize--xxxs);
      gap: 0.5rem;
    }
  }

  &--desktop {
    padding-bottom: 1.5625rem;
  }

  &--vaha {
    #{$scope}-footerLinks {
      font-size: var(--fontSize--xxs);
      gap: 1.625rem;
    }
  }
}
