@import '../../styles/shared';

$scope: '.Imprint';

#{$scope} {
  @extend %Component--container;

  font-size: 1.5rem;
  margin-bottom: 4rem;
  width: 100%;

  &-textContainer {
    width: 100%;
  }

  &-boldText {
    font-weight: 700;
  }

  &-link {
    color: currentcolor;
  }

  &-gradient {
    @extend %Page--bottomGradient;
  }

  &-heading {
    @extend %Page--header;
  }

  &--mobile {
    @extend %Component--containerMobile;

    margin-bottom: 2rem;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      font-size: var(--fontSize-m);
    }

    #{$scope}-textContainer {
      font-size: var(--fontSize--xxxs);
    }

    #{$scope}-gradient {
      @extend %Page--bottomGradientMobile;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-textContainer {
      font-size: var(--fontSize--m);
    }

    #{$scope}-gradient {
      @extend %Page--bottomGradientVaha;
    }
  }
}
