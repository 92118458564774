@import './../../../styles/shared';

$scope: '.FaqItem';
$control-icon-size: 1.5em;

#{$scope} {
  &-questionContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &-question {
    font-weight: 700;
    font-size: var(--fontSize--xs);
    max-width: 95%;
  }

  &-iconContainer {
    position: relative;
    height: 1.5rem;
  }

  &-controlIcon {
    position: absolute;
    top: 0.125rem;
    right: 0;
    width: $control-icon-size;
  }

  &-answer {
    font-size: var(--fontSize--xxs);
    max-height: 0;
    overflow: hidden;
    transition-duration: 0.5s;
    transition-property: max-height;
    margin-top: 0.5rem;
  }

  &-answer--expanded {
    // taking into account parent(body) line height equal to 1.4
    // `28em / 1.4 = 20` lines of text can be rendered in expandable
    max-height: 28em;
    transition-duration: 1s;
  }

  &--mobile {
    $control-icon-size: 1em;

    #{$scope}-controlIcon {
      width: $control-icon-size;
    }

    #{$scope}-question {
      font-size: 0.875rem;
    }

    #{$scope}-answer {
      font-size: 0.5rem;
    }
  }

  &--vaha {
    #{$scope}-question {
      font-size: 2rem;
    }

    #{$scope}-controlIcon {
      top: 0.375rem;
    }

    #{$scope}-answer {
      font-size: 1.75rem;
    }
  }
}
