$scope: '.PairedImages';

#{$scope} {
  display: flex;

  &-image {
    --Image-aspectRatio: 0.5;

    width: 50%;
    border-radius: 0;
  }

  &--mobile {
    #{$scope}-image {
      height: 13vw;
    }
  }

  &--vaha {
    #{$scope}-image {
      height: 12.5vw;
    }
  }
}
