$distance-between-arrows: 38px;
$scope: '.AnimatedScroll';

#{$scope} {
  position: absolute;
  height: 60px;
  margin: 0 0 0 -40px;
  left: 50%;
  top: 85%;
  z-index: 100;

  &-downArrow {
    position: absolute;
    opacity: 0;
    line-height: 60px;
    width: 80px;
    color: var(--color-defaultContrast);
    text-align: center;
    font-size: 70px;
    text-decoration: none;
    text-shadow: 0 0 3px rgb(0 0 0 / 40%);
    animation: change-transparency 2s ease-in-out infinite;
    animation-iteration-count: 2;
    animation-fill-mode: backwards;

    &:nth-child(2) {
      top: #{$distance-between-arrows};
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      top: calc(2 * #{$distance-between-arrows});
      animation-delay: 0.6s;
    }
  }
}

@keyframes change-transparency {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
