@import './../../styles/shared';

$scope: '.HydrationStation';

#{$scope} {
  @extend %Component--container;

  display: flex;
  flex: 1;
  width: 100%;

  &-heading {
    @extend %Page--header;
  }

  &-waterDropSection {
    margin-top: 6.25rem;
    margin-bottom: 4rem;
  }

  &-hydrationStationImage {
    --Image-aspectRatio: calc(591 / 1536);

    border-radius: 1rem;
    width: 100%;
  }

  &-legal {
    padding-top: 2rem;
    text-align: center;
    max-width: 80%;
    font-size: var(--fontSize--xxxs);
    line-height: 1.2;
  }

  &--mobile &-legal {
    padding-top: 1rem;
    max-width: 100%;
    font-size: var(--fontSize--xxxxs);
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      margin-bottom: 1rem;
    }

    #{$scope}-waterDropSection {
      margin-top: 2rem;
      margin-bottom: 1.375rem;
      padding: 0;
    }

    #{$scope}-hydrationStationImage {
      --Image-aspectRatio: calc(161 / 323);
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-waterDropSection {
      margin-top: 6.5625rem;
      margin-bottom: 5.75rem;
    }

    #{$scope}-hydrationStationImage {
      --Image-aspectRatio: calc(475 / 952);
    }
  }
}
