@use 'sass:math';
@import 'src/styles/shared';

$scope: '.HydrationStationCollage';

#{$scope} {
  position: relative;
  width: 100%;
  min-width: 500px;
  height: 950px;
  z-index: 0;

  &-image {
    position: absolute;
    border-radius: 2rem;
    overflow: hidden;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    @extend %CustomGradient;

    &::before {
      inset: -1px;
      width: auto;
      height: auto;
      border-radius: inherit;
    }
  }

  &-collage1 {
    width: 464px;
    height: 555px;
    top: 45%;
    transform: translateY(-45%);
    left: 0;
    z-index: 1;
  }

  &-collage2 {
    width: 569px;
    height: 555px;
    top: 0;
    left: 38%;
    transform: translateX(-38%);
  }

  &-collage3 {
    width: 464px;
    height: 555px;
    bottom: 0;
    left: 52%;
    transform: translateX(-52%);
    z-index: 1;
  }

  &-collage4 {
    width: 372px;
    height: 459px;
    top: 18%;
    right: 16%;
    transform: translate(-16%, -18%);
  }

  &-collage5 {
    width: 376px;
    height: 555px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  @media (width <=1400px) {
    &-image {
      border-radius: 1rem;
    }
  }

  @media (width <=800px) {
    &-image {
      border-radius: 0.5rem;
    }
  }

  $min-media-width: 400;
  $max-media-width: 1600;
  $min-zoom: 0.22;
  $max-zoom: 0.95;
  $count: 20;
  $step-zoom: math.div($max-zoom - $min-zoom, $count);
  $step-media-width: math.div($max-media-width - $min-media-width, $count);

  @while $count >= 0 {
    @media (width <=#{$min-media-width + $step-media-width * $count}px) {
      zoom: $min-zoom + $step-zoom * $count;
    }

    $count: $count - 1;
  }
}
