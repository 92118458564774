$scope: '.Header';

#{$scope} {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
  position: relative;
  width: 100%;

  &-logoButton,
  &-homeButton,
  &-backButton {
    background-color: transparent;
    border-width: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-logoButton {
    height: 1.375rem;
  }

  &-logo {
    width: 20.625rem;
  }

  &-homeButton {
    position: absolute;
    right: 5vw;
  }

  &-backButton {
    position: absolute;
    left: 5vw;
    padding-right: 1rem;
  }

  &-home {
    height: 1.4rem;
  }

  &-back {
    height: 2rem;
  }

  &-languages {
    position: absolute;
    right: 5vw;
    padding: 0 0.375rem;
    font-size: var(--fontSize--xxs);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    border: 2px solid currentcolor;
    border-radius: 0.5rem;
    background-color: transparent;
  }

  &--desktop,
  &--vaha {
    position: sticky;
    background-color: var(--color-default);
    top: 0;
    z-index: 1;
  }

  &--desktop &-languages,
  &--desktop &-homeButton {
    right: 4rem;
  }

  &--desktop &-backButton {
    left: 4rem;
  }

  &--mobile {
    position: relative;
    padding: 3rem 0;

    #{$scope}-languages {
      font-size: var(--fontSize--xxxxs);
      border-width: 1px;
      border-radius: 0.25rem;
      padding: 0 0.1875rem;
    }

    #{$scope}-logoButton {
      height: 0.6325rem;
    }

    #{$scope}-logo {
      width: 9.5rem;
    }

    #{$scope}-home {
      height: 0.75rem;
    }

    #{$scope}-back {
      height: 1.25rem;
    }
  }

  &--vaha {
    padding-top: 5px;

    #{$scope}-home {
      height: 2rem;
    }

    #{$scope}-languages {
      font-size: var(--fontSize--xs);
      line-height: 1.46;
    }
  }
}
