$scope: '.WaterDropItem';

#{$scope} {
  width: 100%;

  &-introImage {
    --Image-aspectRatio: calc(447 / 894);

    width: 100%;
    margin-bottom: 4.5rem;
  }

  &--desktop &-introImage {
    --Image-aspectRatio: calc(600 / 1568);

    margin-bottom: 7rem;
  }

  &--mobile &-introImage {
    margin-bottom: 1rem;
  }

  &-gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr) 0 repeat(5, 1fr);
    grid-template-rows: 1fr auto;
  }

  &-pipe {
    outline: 2px solid;
    grid-column: 7;
    height: 2rem;
    width: 0;
  }

  &-connectingLines {
    border: 4px solid;
    grid-column: 3 / span 7;
    border-bottom: none;
    height: 6rem;
  }

  &-singleItem {
    display: flex;
    flex-direction: column;
    grid-row: 3;
    grid-column: 1 / span 4;
    text-align: center;
    align-items: center;
    justify-self: center;

    &:first-child {
      grid-column: 1 / span 4;
    }

    &:last-child {
      grid-column: 8 / span 4;
    }
  }

  &-title {
    text-align: center;
    font-size: 2.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-subtitle {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  &-item-title {
    font-size: 2.875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3.5rem;
  }

  &-hydrationStationImage {
    --Image-aspectRatio: calc(208 / 612);

    border-radius: 0;
    width: 11.5vw;
  }

  &-item-description {
    font-size: 2.25rem;
    margin-top: 0.5rem;
    line-height: 1.1;
  }

  &-itemLegal {
    font-size: var(--fontSize--xxxs);
    line-height: 1.2;
    padding-top: 1rem;
  }

  &--mobile &-itemLegal {
    padding-top: 0.5rem;
    font-size: var(--fontSize--xxxxs);
  }

  &--mobile {
    #{$scope}-heading {
      color: #000;
      font-size: var(--fontSize--s);
      margin-bottom: 1rem;
    }

    #{$scope}-title {
      font-size: 0.875rem;
    }

    #{$scope}-subtitle {
      font-size: 0.625rem;
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

    #{$scope}-pipe {
      height: 0.625rem;
      outline: 0.5px solid;
    }

    #{$scope}-connectingLines {
      border: 1px solid;
      border-bottom: none;
      height: 0.625rem;
    }

    #{$scope}-item-title {
      margin-top: 0.5rem;
      font-size: var(--fontSize--xxxs);
    }

    #{$scope}-item-description {
      margin-top: 0.125rem;
      font-size: 0.6875rem;
    }

    #{$scope}-hydrationStationImage {
      --Image-aspectRatio: calc(48 / 120);

      min-width: 3rem;
    }
  }

  &--vaha {
    #{$scope}-subtitle {
      font-size: 2rem;
      margin-top: 0.625rem;
      margin-bottom: 0.325rem;
    }

    #{$scope}-pipe {
      height: 1.5rem;
      outline: 1px solid;
    }

    #{$scope}-connectingLines {
      border: 1px solid;
      border-bottom: none;
      height: 2rem;
    }

    #{$scope}-item-title {
      margin-top: 1.5rem;
      font-size: 2rem;
    }

    #{$scope}-item-description {
      font-size: var(--fontSize--s);
    }
  }
}
