@import './../../styles/shared';

$scope: '.WaterDropSection';

#{$scope} {
  @extend %Component--container;

  width: 100%;
  display: flex;
  padding-left: 0;
  padding-right: 0;

  &-container {
    gap: 6.75rem;
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(8rem, 1fr));
  }

  &-singleItem {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &-title {
    text-align: center;
    font-size: 2.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &--desktop &-title {
    padding-bottom: 0.75rem;
  }

  &-pairedImages {
    margin-bottom: 3.375rem;
  }

  &-description {
    font-size: 2.25rem;
    text-align: center;
  }

  &-more {
    font-weight: 700;
    text-decoration: underline;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-container {
      grid-template-columns: repeat(3, minmax(4rem, 1fr));
      gap: 1rem;
    }

    #{$scope}-pairedImages {
      margin-top: 0;
      margin-bottom: 0.75rem;
    }

    #{$scope}-title {
      font-size: var(--fontSize--xxxs);
      margin-bottom: 0.25rem;
    }

    #{$scope}-description {
      font-size: 0.6875rem;
    }
  }

  &--vaha {
    #{$scope}-container {
      gap: 4rem;
    }

    #{$scope}-pairedImages {
      margin-top: 0;
      margin-bottom: 2.5rem;
    }

    #{$scope}-title {
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }

    #{$scope}-description {
      font-size: 1.875rem;
    }
  }
}
