$scope: '.VideoPlayerVolume';

#{$scope} {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &-soundIcon {
    width: 1rem;
  }

  &-soundBar {
    position: relative;
    width: 4px;
    height: 128px;
    background-color: #5f5f5f;
    display: flex;
    flex-direction: column;
  }

  &-scale {
    position: absolute;
    inset: 0;
  }

  &-level {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(var(--level) * 100%);
    background-color: white;
  }

  input[type="range"] {
    // make it render vertically
    // see also [orient="vertical"] in the template for FF
    // @see: https://stackoverflow.com/a/15935838/1879162
    writing-mode: bt-lr;
    appearance: slider-vertical;

    // keep it invisible but interactive
    opacity: 0;

    // place it on top of the `scale`
    position: absolute;
    inset: 0;
    margin: 0;
    padding: 0;
    height: 100%;

    // make it wider so it's easier for user to tap
    width: 300%;
    left: -100%;
  }
}
