@import '../../../styles/shared';

$scope: '.ReadAndRechargeView';

#{$scope} {
  @extend %Component--container;

  --Link-color: var(--color--purple);

  margin-bottom: 4em;

  &-content {
    padding: 0 6em;
  }

  &-heading {
    @extend %Page--header;
  }

  &-caption {
    align-self: flex-start;
    font-size: var(--fontSize--m);
    font-weight: 600;
    text-transform: uppercase;
  }

  &-title {
    font-size: var(--fontSize--l);
    line-height: 1;
    font-weight: 700;
    align-self: flex-start;
    margin-bottom: 0.5em;
  }

  &-paragraph {
    padding-bottom: 1em;
  }

  &-link {
    color: currentcolor;
    text-decoration: none;
  }

  &-box {
    text-align: justify;
    font-size: var(--fontSize--s);
  }

  &-tile,
  &-cars {
    padding-top: 8em;
  }

  &-tile,
  &-car {
    @extend %CustomGradient;

    display: flex;
  }

  &-tileImage,
  &-carImage {
    border-radius: 1.75em;
    object-fit: cover;
    width: 100%;
  }

  &-cars {
    display: flex;
    gap: 8em;
  }

  &-car {
    flex: 1;
  }

  &-museumQrCode {
    padding: 0;
    align-items: flex-start;
  }

  &--mobile {
    @extend %Component--containerMobile;

    #{$scope}-heading {
      @extend %Page--headerMobile;
    }

    #{$scope}-content {
      padding: 0 2vw;
    }

    #{$scope}-caption {
      line-height: 1.8;
      font-size: var(--fontSize--xxs);
    }

    #{$scope}-title {
      font-size: var(--fontSize--xs);
    }

    #{$scope}-paragraph {
      font-size: var(--fontSize--xxs);
    }

    #{$scope}-qrCode {
      margin-top: 5.75em;
    }

    #{$scope}-tile,
    #{$scope}-cars {
      padding-top: 2em;
    }

    #{$scope}-cars {
      flex-direction: column;
      gap: 2em;
    }

    #{$scope}-tileImage,
    #{$scope}-carImage {
      border-radius: 1em;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-content {
      padding: 0 4em;
    }

    #{$scope}-qrCode {
      margin-top: 5.75em;
    }

    #{$scope}-tile,
    #{$scope}-cars {
      padding-top: 4em;
    }

    #{$scope}-cars {
      flex-direction: column;
      gap: 4em;
    }

    #{$scope}-tileImage,
    #{$scope}-carImage {
      border-radius: 1em;
    }
  }
}
