@import './../../styles/shared';

$scope: '.ScentingStation';

#{$scope} {
  @extend %Component--container;

  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100vw;

  &-heading {
    @extend %Page--header;
  }

  &-mainTextContainer {
    font-size: 2.25rem;
    line-height: 1.2;
  }

  &-mainImage {
    --Image-aspectRatio: calc(379 / 834);

    border-radius: 0;
    float: left;
    object-fit: contain;
    width: 44vw;
    margin-right: 3rem;
  }

  &-carousel {
    @extend %Component--carousel;

    display: none;

    /* stylelint-disable-next-line selector-class-pattern */
    .slick-track {
      display: flex;
      gap: 12rem;

      &::before,
      &::after {
        display: none;
      }
    }

    max-width: 100vw;
    width: 100%;
    margin-top: 6.5rem;
    margin-bottom: 8rem;
  }

  &-bottom-image {
    max-width: 100%;
    border-radius: 1rem;
  }

  &--mobile {
    @extend %Component--containerMobile;

    font-size: var(--fontSize--xxs);
    max-width: 100vw;

    #{$scope}-heading {
      @extend %Page--headerMobile;

      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    #{$scope}-mainTextContainer {
      @extend %Page--descriptionMobile;

      font-size: 0.6875rem;
      line-height: 1.1;
    }

    #{$scope}-mainImage {
      --Image-aspectRatio: calc(146 / 315);

      margin-right: 1rem;
    }

    #{$scope}-carousel {
      @extend %Component--carouselMobile;

      /* stylelint-disable-next-line selector-class-pattern */
      .slick-track {
        gap: 1.5rem;
      }

      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    #{$scope}-singleItem {
      max-width: 22vw;
    }

    #{$scope}-bottom-image {
      border-radius: 0.5rem;
    }
  }

  &--vaha {
    #{$scope}-heading {
      @extend %Page--headerVaha;
    }

    #{$scope}-mainTextContainer {
      font-size: var(--fontSize--s);
    }

    #{$scope}-mainImage {
      --Image-aspectRatio: calc(421 / 930)
    }

    #{$scope}-carousel {
      @extend %Component--carouselVaha;

      margin-bottom: 5rem;

      /* stylelint-disable-next-line selector-class-pattern */
      .slick-track {
        gap: 3rem;
      }
    }
  }
}
