$scope: '.IdlePrompt';

#{$scope} {
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding: 0 2rem;
  }

  &-text {
    padding: 1.5em 0 0.5em;
    font-size: 2.875rem;
  }

  &-cancel,
  &-redirect {
    width: 100%;
    padding: 0.5rem 2rem;
    background: none;
    appearance: none;
    font-size: 2.5rem;
    border: 2px solid;
    border-radius: 0.75rem;
    outline: none;
    font-weight: 700;
    cursor: pointer;
  }
}
